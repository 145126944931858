export enum HeadingTypes {
  HEADING_1 = 'HEADING_1',
  HEADING_2 = 'HEADING_2',
  HEADING_3 = 'HEADING_3',
  HEADING_4 = 'HEADING_4',
  HEADING_5 = 'HEADING_5',
  HEADING_6 = 'HEADING_6',
}

export enum ParagraphTypes {
  BODY_XS = 'BODY_XS',
  BODY_XS_BOLD = 'BODY_XS_BOLD',
  BODY_XS_ITALIC = 'BODY_XS_ITALIC',
  BODY_SM = 'BODY_SM',
  BODY_SM_BOLD = 'BODY_SM_BOLD',
  BODY_MD = 'BODY_MD',
  BODY_MD_BOLD = 'BODY_MD_BOLD',
  BODY_MD_BOLD_ITALIC = 'BODY_MD_BOLD_ITALIC',
  BODY_LG = 'BODY_LG',
  BODY_LG_BOLD = 'BODY_LG_BOLD',
  BODY_LG_BOLD_ITALIC = 'BODY_LG_BOLD_ITALIC',
  BODY_XL_BOLD = 'BODY_XL_BOLD',
}
