'use client';

import styled from 'styled-components';
import Icons from './svg/icons.svg';

export const Span = styled.span`
  display: inline-block;
  height: 3.2rem;
  width: 3.2rem;
  fill: currentColor;
  line-height: 1;

  svg {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
`;

export const StyledIconsContainer = styled(Icons)`
  display: none;
`;
